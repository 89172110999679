import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { login, selectError } from '../features/auth/authSlice';
import { selectLastOrders } from '../features/orders/ordersSlice';
import Layout from '../components/Layout';
import OrderStatus from '../components/OrderStatus'
import { List } from '@mui/material';
import { readOrders } from '../features/orders/ordersSlice'


export default function () {
    const dispatch = useDispatch();
    const orders = useSelector(selectLastOrders)
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(readOrders());
    }, [])

    return (<Layout>
        <Container sx={{ pt: 3 }}>
            <Typography align="center" variant="h5">Deine letzten Aufträge</Typography>
        </Container>
        <List dense>
            {orders.map(order => (
            <ListItem secondaryAction={<IconButton onClick={() => navigate(`/order/step/serviceRecipient/${order.id}`)}><EditIcon /></IconButton>}>
                <ListItemButton onClick={() => navigate(`/order/step/serviceRecipient/${order.id}`)}>
                    <ListItemText primary={`${order.name}`} secondary={<div><div>{`${order.address} ${order.postcode} ${order.city}`}</div><div> <OrderStatus order={order} /></div></div>} />
                </ListItemButton>
            </ListItem>))}
        </List>
    </Layout>);
}