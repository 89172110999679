import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';

const initialState = {
    work : []
}

export const loadExtraWork = createAsyncThunk(
  'extraWork/load',
  // The payload creator receives the partial `{title, content, user}` object
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState();
    const client  = createClient(auth.token);
    // We send the initial data to the fake API server
    try {
      const response = await client.get('/extraWork')
      // The response includes the complete post object, including unique ID
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const extraWorkSlice = createSlice({
  name: 'extraWork',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(loadExtraWork.fulfilled, (state, action) => {
        state.work = action.payload
      })
  }
})

// Action creators are generated for each case reducer function

export const selectExtraWork = (state) => state.extraWork?.work;
export default extraWorkSlice.reducer