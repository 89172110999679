import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';

const initialState = {
  isLoggedIn: false,
  user: {},
  error: {},
  token : ""
}

export const login = createAsyncThunk(
  'auth/login',
  // The payload creator receives the partial `{title, content, user}` object
  async (credentials, { rejectWithValue, dispatch }) => {
    const client  = createClient();
    // We send the initial data to the fake API server
    try {
      const response = await client.post('/auth/login', credentials)
      // The response includes the complete post object, including unique ID
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true
        state.user = action.payload.user
        state.token = action.payload.token
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false
        state.error = action.payload
        state.user = {}
      });
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = authSlice.actions

export const selectError = (state) => state.auth.error;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectToken = (state) => state.auth.token;

export default authSlice.reducer