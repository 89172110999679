import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux"
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import authReducer from '../features/auth/authSlice'
import orderReducer from '../features/orders/ordersSlice';
import clientReducer from '../features/client/clientSlice';
import accessoireReducer from '../features/accessoire/accessoireSlice';
import workTypeReducer from '../features/workType/workTypeSlice';
import currentOrderReducer from '../features/currentOrder/currentOrderSlice';
import offlineReducer from '../features/offline/offlineSlice';
import extraWorkSlice from '../features/extraWork/extraWorkSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['currentOrder']
}

export const store = configureStore({
  reducer: persistReducer(persistConfig, combineReducers({
    auth : authReducer,
    order : orderReducer,
    client : clientReducer,
    accessoire : accessoireReducer,
    workType : workTypeReducer,
    currentOrder: currentOrderReducer,
    offline : offlineReducer,
    extraWork : extraWorkSlice
  })),
  middleware : getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store); 

