import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';
import { addPendingRequest } from '../offline/offlineSlice';

const initialState = {
  orders: [],
  filters: {
    search: "",
    status: "all",
  },
  createOrder: {
    reports: [],
    name: ""
  },
  currentStep: 1,
  page: 0,
  last_page: 0
}

const pageSize = 5;

export const readOrders = createAsyncThunk(
  'orders/read',
  // The payload creator receives the partial `{title, content, user}` object
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState(); 
    const client = createClient(auth.token);

    // We send the initial data to the fake API server
    try {
      const response = await client.get('/orders')
      // The response includes the complete post object, including unique ID
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const deleteOrder = createAsyncThunk(
  'orders/delete',
  async (orderId, { rejectWithValue, getState, dispatch }) => {
    const { auth, offline : { isOnline } } = getState();
    const client = createClient(auth.token);

    // We send the initial data to the fake API server
    try {

      if(!isOnline){
        dispatch(addPendingRequest({ type : "order/delete", payload : orderId}));
        dispatch(setPendingStatus({ orderId, status: "pendingdelete" }));
        return rejectWithValue({});
      }

      await client.delete(`/orders/${orderId}`)
      // The response includes the complete post object, including unique ID
      dispatch(readOrders());
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const createOrder = createAsyncThunk(
  'orders/create',
  async (order, { rejectWithValue, getState, dispatch }) => {
    const { auth } = getState();
    const client = createClient(auth.token);

    // We send the initial data to the fake API server
    try {
      await client.post(`/orders`, order);
      //dispatch(addOrder(order))
      dispatch(readOrders());
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    saveOrder(state, action) {
      state.createOrder = { ...state.createOrder, ...action.payload }
    },
    addReport(state, action) {
      state.createOrder.reports.push(action.payload);
    },
    replaceReport(state, action) {
      const { values, changeReport } = action.payload;
      const changeIndex = state.createOrder.reports.findIndex(function (cur) {
        return Object.keys(changeReport).every(function (key) {
          return Array.isArray(changeReport[key]) ? JSON.stringify(changeReport[key]) === JSON.stringify(cur[key]) : changeReport[key] === cur[key];
        });
      });

      if (changeIndex >= 0) {
        state.createOrder.reports[changeIndex] = values;
      }
    },
    deleteReport(state, action) {
      const report = action.payload;
      const changeIndex = state.createOrder.reports.findIndex(function (cur) {
        return Object.keys(report).every(function (key) {
          return Array.isArray(report[key]) ? JSON.stringify(report[key]) === JSON.stringify(cur[key]) : report[key] === cur[key];
        });
      });

      if (changeIndex >= 0) {
        state.createOrder.reports = state.createOrder.reports.filter((item, index) => index !== changeIndex);
      }
    },
    setPendingStatus(state, action){
      const { orderId, status } = action.payload;
      state.orders = state.orders.map((order) => {
        if(order.id === orderId){
          order.status = status;
        }
        return order;
      });
    },
    resetOrder(state) {
      state.createOrder = Object.keys(state.createOrder).reduce((prev, val) => { prev[val] = null; return prev }, state.createOrder);
    },
    addOrder(state, action){
      state.orders.push(action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(readOrders.fulfilled, (state, action) => {
        state.orders = action.payload
      })
  }
})

// Action creators are generated for each case reducer function
export const selectOrders = (state) => state.order.orders;
export const selectFilters = (state) => state.order.filters;
export const selectPage = (state) => state.order.page;
export const selectLastOrders = (state) => state.order.orders.slice(0,5);

export const selectFilteredOrders = (state) => {
  var orders = selectOrders(state);
  var { status, search } = selectFilters(state);
  var currentPage = selectPage(state);

  if (status && status !== "all") {
    orders = orders.filter(order => order.status === status);
  }

  if (status) {
    orders = orders.filter(order => order.name.includes(search) || order.address.includes(search) || order.city.includes(search));
  }

  const lastPage = Math.ceil(orders.length / pageSize);
  orders = orders.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  return { orders, lastPage };
}

export const selectReportsByWorkId = (state, workId) => {
  const { reports } = selectCreateOrder(state);
  return reports.filter(report => report.selected_work === workId)
}

export const selectOrderByOrderId = (state, orderId) => {
  return state.order.orders.find(order => order.id === parseInt(orderId));
}

export const selectCreateOrder = (state) => state.order.createOrder;

export const { setFilters, setPage, saveOrder, addReport, replaceReport, deleteReport, resetOrder, setPendingStatus, addOrder } = orderSlice.actions

export default orderSlice.reducer