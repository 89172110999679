import React from 'react';
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import MobileStepper from '@mui/material/MobileStepper';

import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layout'

const StepWrapper = ({ activeStep, handleSave, children }) => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const steps = [
        { name: 'Leistungsempfänger', route: "/order/step/serviceRecipient/" },
        { name: 'Zusatzgeräte', route: "/order/step/accessories/" },
        { name: 'Ausgeführte Arbeiten', route: "/order/step/workPerformed/" },
        { name: 'Auftrag bestätigen', route: "/order/step/confirmation/" }
    ];
    const maxSteps = steps.length;

    const handleBack = () => {
        if(orderId){
            navigate(`${steps[activeStep-1].route}${orderId}`);
        } else {
            navigate(`${steps[activeStep-1].route}`);
        }
    }

    if (activeStep === maxSteps - 1) {
        var nextButton = (<Button
            size="small"
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleSave}
        >Speichern </Button>);
    } else {
        var nextButton = (<Button
            size="small"
            onClick={handleSave}>Weiter <KeyboardArrowRight /></Button>);
    }

    return (<Layout>
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ pt: 4, pb: 2 }}>
                    {children}
                </Box>
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={nextButton}
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                            Zurück
                        </Button>
                    }
                />
            </Box>
        </Container>
    </Layout>)
};

export { StepWrapper }