import { useEffect, forwardRef, useRef } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { useForm, FormProvider } from 'react-hook-form';
import { string, object, number } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from "zod"
import { useDispatch } from 'react-redux';
import { addReport, replaceReport } from '../../features/currentOrder/currentOrderSlice';
import FormSelect from '../../components/FormSelect';
import { useSelector } from 'react-redux';
import { selectExtraWork } from '../../features/extraWork/extraWorkSlice';


const registerSchema = object({
    value1: z.coerce.number().min(1, { message: "Das Feld wird benötigt" }),
    value2: z.coerce.number().min(1, { message: "Das Feld wird benötigt" }),
    value3: z.coerce.number().min(1, { message: "Das Feld wird benötigt" }),
    material: string().min(1, { message: "Das Feld wird benötigt" }),
    wd: string().min(1, { message: "Das Feld wird benötigt" }),
    location: string(),
    work: string().array(),
    selected_work: number(),
    other: string(),
    work_height: number(),
    worn_material: string()
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CreateEntryModal({ open, handleClose, selectedWork, changeReport }) {
    const dispatch = useDispatch();
    const extrawork = useSelector(selectExtraWork)
    const form = useRef();
    var currentSchema = registerSchema;
    if (selectedWork) {
        var ignoreFields = [...selectedWork?.hidden_fields,];

        for (var i = selectedWork.value_lables.length + 1; i <= 3; i++) {
            ignoreFields.push(`value${i}`);
        }
        console.log(ignoreFields);
        currentSchema = registerSchema.omit(ignoreFields.reduce((prev, val) => { prev[val] = true; return prev; }, {}));
        console.log(currentSchema);
    }
    console.log('selected_work ' + selectedWork?.id)
    const methods = useForm({ resolver: zodResolver(currentSchema), defaultValues: { work: [], selected_work: selectedWork?.id, } });
    const { handleSubmit, register, formState: { errors }, setValue, reset, getValues, watch } = methods;

    watch(["work", "md"])

    useEffect(() => setValue("selected_work", selectedWork?.id), [selectedWork]);
    useEffect(() => {
        console.log('change report: ' + changeReport);
        (changeReport) ? reset(changeReport) : reset();
    }, [changeReport])
    console.log('values: ' + getValues().selected_work);
    const onSubmitHandler = (values) => {
        console.log('change report in handler: ' + changeReport);
        if (changeReport) {
            dispatch(replaceReport({ changeReport, values }));
        } else {
            setValue("selected_work", selectedWork?.id);
            dispatch(addReport(values));
        }
        handleClose();
        reset();
    }

    const handleSave = () => {
        setValue("selected_work", selectedWork?.id);
        console.log('values: ' + getValues().selected_work);
        form.current.requestSubmit();
        reset();
    }

    console.log(errors);
    const isHiddenField = (field) => selectedWork?.hidden_fields?.includes(field);

    const valueFields = selectedWork?.value_lables.map((item, index) => {
        const fieldName = `value${index + 1}`;
        return <Grid item xs={12} key={index}>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <OutlinedInput
                    required
                    id="outlined-adornment-weight"
                    type="number"
                    placeholder={item}
                    error={errors[fieldName]}
                    endAdornment={<InputAdornment position="end">{(item == 'Menge' || item.includes("Anzahl")) ? "" : "cm"}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                    {...register(fieldName)}
                />
                {!!errors[fieldName] && (
                    <FormHelperText error id="accountId-error">
                        {errors[fieldName].message}
                    </FormHelperText>
                )}
            </FormControl>
        </Grid>

    });


    return (
        <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {(!changeReport) ? 'Arbeit hinzufügen' : 'Arbeit ändern'}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSave}>
                        Speichern
                    </Button>
                </Toolbar>
            </AppBar>
            <FormProvider {...methods} >
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmitHandler)}
                    ref={form}
                >
                    <DialogContent>
                        <Grid container>
                            {valueFields}

                            {!isHiddenField("material") && <Grid item xs={12}>
                                <FormControl sx={{ my: 1 }} fullWidth>
                                    <InputLabel id="material-select-label">Material</InputLabel>
                                    <FormSelect id="material-select" labelId="material-select-label" label="Material" name='material'>
                                        <MenuItem value={'b'}>Beton</MenuItem>
                                        <MenuItem value={'m'}>Mauerwerk</MenuItem>
                                    </FormSelect>
                                    {!!errors.material && (
                                        <FormHelperText error>
                                            {errors.material?.message}
                                        </FormHelperText>
                                    )}

                                </FormControl>
                            </Grid>}
                            {!isHiddenField("wd") && <Grid item xs={12}>
                                <FormControl sx={{ my: 1 }} fullWidth>
                                    <InputLabel id="wd-select-label">Wand/Decke</InputLabel>
                                    <FormSelect id="wd-select" labelId="wd-select-label" label="Wand/Decke" error={errors.wd} name="wd">
                                        <MenuItem value={'w'}>Wand</MenuItem>
                                        <MenuItem value={'d'}>Decke</MenuItem>
                                    </FormSelect>
                                    {!!errors.wd && (
                                        <FormHelperText error id="accountId-error">
                                            {errors.wd?.message}
                                        </FormHelperText>
                                    )}

                                </FormControl>
                            </Grid>}
                            {!isHiddenField("work") && <Grid item xs={12}>
                                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                                    <InputLabel id="select-work-label">Ausgeführte Arbeiten</InputLabel>
                                    <FormSelect placeholder="Ausgeführte Arbeiten" labelId='select-work-label' label="Ausgeführte Arbeiten" name="work" multiple>

                                        {extrawork.map((item, key) => <MenuItem key={key} value={item}>
                                            {item}
                                        </MenuItem>)}
                                    </FormSelect>
                                    {!!errors.work && (
                                        <FormHelperText error id="accountId-error">
                                            {errors.work?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>}
                            {!isHiddenField("work_height") && <Grid item xs={12}>
                                <FormControl sx={{ my: 1 }} fullWidth variant="outlined">
                                    <InputLabel id="select-work-height-label">Arbeitshöhe</InputLabel>
                                    <FormSelect placeholder="Arbeitshöhe" labelId='select-work-height-label' name="work_height" label="Arbeitshöhe">
                                        <MenuItem value={1}>unter 2m</MenuItem>
                                        <MenuItem value={2}>über 2m</MenuItem>
                                        <MenuItem value={3}>über 4m</MenuItem>
                                    </FormSelect>
                                    {!!errors.work_height && (
                                        <FormHelperText error>
                                            {errors.work_height?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>}
                            {!isHiddenField("worn_material") && <Grid item xs={12} >
                                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-weight"
                                        type="number"
                                        placeholder="Materialabtrag"
                                        error={errors.worn_material}
                                        endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        {...register('worn_material')}
                                    />
                                    {!!errors.worn_material && (
                                        <FormHelperText error id="accountId-error">
                                            {errors.worn_material?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>}
                            
                            {!isHiddenField("location") && <Grid item xs={12}>
                                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                                    <OutlinedInput
                                        placeholder='Ort'
                                        error={errors.location}
                                        {...register("location")}
                                        aria-describedby="outlined-weight-helper-text"
                                    />
                                    {!!errors.location && (
                                        <FormHelperText error id="accountId-error">
                                            {errors.location?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>}
                            <Grid item xs={12}>
                                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                                    <OutlinedInput
                                        {...register("other")}
                                        placeholder='Sonstiges'
                                        aria-describedby="outlined-weight-helper-text"
                                    />
                                    {!!errors.other && (
                                        <FormHelperText error id="accountId-error">
                                            {errors.other?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>
            </FormProvider>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button type='submit' variant="contained" onClick={handleSave}>Speichern</Button>
            </DialogActions>
        </Dialog>
    )
}

export { CreateEntryModal }