import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { onlineStatusChange, processPendingRequests } from "./features/offline/offlineSlice";

export default function({children}) {
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("LAYOUT USE EFFECT")
        window.addEventListener('offline', (e) => dispatch(onlineStatusChange(false)));
        window.addEventListener('online',  (e) => { 
            console.log(e);
            dispatch(onlineStatusChange(true)); 
            dispatch(processPendingRequests({})); 
        });    
    }, [])
    

    return (<>{children}</>)

}