import { Container, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../components/ConfirmDialog";
import Layout from "../components/Layout"
import OrderStatus from "../components/OrderStatus";
import { useSelector, useDispatch } from 'react-redux';
import { selectFilteredOrders, readOrders, setFilters, selectFilters, selectPage, setPage, deleteOrder } from '../features/orders/ordersSlice'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orders, lastPage } = useSelector(selectFilteredOrders);
    const filters = useSelector(selectFilters);
    const page = useSelector(selectPage);
    const [deleteItem, setDeleteItem] = useState(null);
    const [stateFilters, setStateFilters] = useState(filters);

    const handleClickOpen = (item) => {
        setDeleteItem(item);
    };

    const handleClose = () => {
        setDeleteItem(null);
    };

    const handleDelete = () => {
        dispatch(deleteOrder(deleteItem.id)).then(() => handleClose())
    }

    const handleFilterChange = (name) => (e) => {
        setStateFilters({...stateFilters, [name] : e.target.value});
    }

    const handleFilterSubmit = () => {
        dispatch(setFilters(stateFilters));
        dispatch(setPage(1));
    }

    const handlePageChange = (e, page) => dispatch(setPage(page));

    useEffect(() => {
        dispatch(readOrders());
        dispatch(setPage(1));
    }, [])

    return (<Layout>
        <Container sx={{ pt: 3 }}>
            <Typography align="center" variant="h5">Deine Aufträge</Typography>
            <Grid sx={{ mt: 3 }}>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="status-simple-select"
                            label="Status"
                            value={stateFilters.status}
                            onChange={handleFilterChange("status")}
                        >
                            <MenuItem value="all">Alle</MenuItem>
                            <MenuItem value="open">Offen</MenuItem>
                            <MenuItem value="calculated">Erfasst</MenuItem>
                            <MenuItem value="closed">Übertragen</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sx={{ mt: 1 }}>
                    <FormControl fullWidth>
                        <TextField label="Suche.." onChange={handleFilterChange("search")} value={stateFilters.search} id="search-input" aria-describedby="Search" />
                    </FormControl>
                </Grid>
                <Grid item sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" onClick={handleFilterSubmit}>Filtern</Button>
                </Grid>
            </Grid>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {orders?.map(order => <ListItem
                    key={order.id}
                    secondaryAction={
                        order.status !== "pendingdelete" && order.status !== "pendingcreation" && <>
                            <IconButton edge="end" aria-label="comments"  onClick={() => navigate(`/order/step/serviceRecipient/${order.id}`)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="comments"  onClick={() => handleClickOpen(order)}>
                                <DeleteIcon />
                            </IconButton>
                        </>

                    }
                    disablePadding
                >
                    <ListItemButton role={undefined}>
                        <ListItemText primary={`${order.name}`} secondary={<div><div>{`${order.address} ${order.postcode} ${order.city}`}</div><div> <OrderStatus order={order} /></div></div>} />
                    </ListItemButton>
                </ListItem>)}
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Pagination count={lastPage} page={page} onChange={handlePageChange} />
            </Box>
        </Container>
        <ConfirmDialog deleteItem={deleteItem} handleClose={handleClose} handleDelete={handleDelete} dialogTitle="Auftrag löschen">
            Sind Sie sicher das der Auftrag {deleteItem?.name} gelöscht werden soll? Dies kann nicht mehr Rückgäng gemacht werden.
        </ConfirmDialog>
    </Layout>);
}   