import React, { useEffect, useState, createRef } from 'react';
import dayjs from 'dayjs';
import { FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useNavigate, useParams } from 'react-router-dom';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useDispatch, useSelector } from 'react-redux'
import { loadClients, selectClients } from '../../../features/client/clientSlice';
import { selectCurrentOrder, setOrder, changeOrder, resetOrder } from '../../../features/currentOrder/currentOrderSlice';
import { setCurrentOrderByOrder, selectOrderByOrderId } from '../../../features/orders/ordersSlice';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { literal, nullable, number, object, string } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { StepWrapper } from '../StepWrapper';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';


const registerSchema = object({
    client_id: number({ invalid_type_error: "Das Feld wird benötigt" }),
    name: string({ required_error: "Das Feld wird benötigt" }).min(1)
        .max(255, 'Der Name muss kürzer als 255 Zeichen sein'),
    address: string({ required_error: 'Das Feld wird benötigt' })
        .max(255, 'Der Name muss kürzer als 255 Zeichen sein').min(1),
    postcode: string({ required_error: 'Das Feld wird benötigt' }).regex(/^[0-9]{5}$/, "Keine gültige Postleitszahl"),
    city: string({ required_error: 'Das Feld wird benötigt' }).min(1),
    date: string({ required_error: 'Das Feld wird benötigt' }).min(1),
    duration: string({ required_error: 'Das Feld wird benötigt', invalid_type_error: "Das Feld wird benötigt" }).min(1),
    notes: string()
});

const ServiceRecipient = () => {
    const { orderId } = useParams();

    const currentOrder = useSelector(state => selectOrderByOrderId(state, orderId));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const order = useSelector(selectCurrentOrder);

    const clients = useSelector(selectClients);
    const form = createRef();
    const methods = useForm({
        resolver: zodResolver(registerSchema),
        defaultValues: { client_id: 1, notes: ''}
    });

    methods.watch(["name", "date", "duration", "client_id"]);

    useEffect(() => {
        methods.reset(currentOrder);
        if(currentOrder?.pivot){
            methods.setValue("duration", currentOrder.pivot?.duration)
        }
    }, [currentOrder]);

    useEffect(() => {
        console.log(orderId);
        if (orderId && !order.id || (orderId && orderId != order.id)) {
            console.log("currentOrder: ", currentOrder)
            dispatch(setOrder(currentOrder));
        }
        if (!orderId) {
            dispatch(resetOrder());
            methods.setValue("date", dayjs().format("YYYY-MM-DD"));
            methods.setValue("duration", null);
        }
        dispatch(loadClients());
    }, [orderId]);



    const handleDateChange = value => {
        methods.setValue("date", value.format("YYYY-MM-DD"));
    }

    const handleTimeChange = value => {
        methods.setValue("duration", value.format("HH:mm:ss"));
    }

    const onSubmitHandler = (values) => {
        dispatch(changeOrder(values));
        console.log(orderId)
        if (orderId) {
            navigate(`/order/step/accessories/${orderId}`);
        } else {
            navigate(`/order/step/accessories/`);
        }
    };

    const handleSave = () => {
        if(!methods.getValues('notes')) {
            methods.setValue('notes','');
        }
        form.current.requestSubmit()
    };

    return (
        <StepWrapper activeStep={0} handleSave={handleSave}>
            <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>Leistungsempfänger</Typography>
            <FormProvider {...methods} >
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                    ref={form}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="customer-label">Auftraggeber</InputLabel>
                                <FormSelect
                                    label="Auftraggeber"
                                    name="client_id"
                                >
                                    {clients?.map((item) => <MenuItem key={item.id} value={item.id}>{item.company}</MenuItem>)}
                                </FormSelect>

                                <FormHelperText error>{methods.formState.errors['client_id'] ? methods.formState.errors['client_id'].message : 'Bitte ein Lieferschein in Papierform erstellen, falls Kunde nicht vorhanden.'}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                name="name"
                                label="Name der Baustelle"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                name="address"
                                label="Straße und Hausnummer (Baustelle)"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormInput
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                name="postcode"
                                label="PLZ (Baustelle)"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormInput
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                name="city"
                                label="Ort (Baustelle)"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MobileDatePicker
                                label="Datum"
                                inputFormat="DD.MM.YYYY"
                                required
                                fullWidth
                                value={methods.getValues("date")}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField fullWidth required {...params} error={!!methods.formState.errors['date']} helperText={methods.formState.errors['date']?.message} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                ampm={false}
                                views={['hours', 'minutes']}
                                inputFormat="HH:mm"
                                mask="__:__"
                                label="Einsatzdauer"
                                value={dayjs(methods.getValues("duration"), "HH:mm:ss")}
                                onChange={handleTimeChange}
                                renderInput={(params) => <TextField required fullWidth {...params} error={!!methods.formState.errors['duration']} helperText={methods.formState.errors['duration']?.message} />}
                            />
                        </Grid>
                      
                        <Grid item xs={12}>
                            <FormInput
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                name="notes"
                                label="Sonstiges"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                />
                        </Grid>
                    </Grid>
                </Box>
            </FormProvider>
        </StepWrapper>
    )
};

export default ServiceRecipient