import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function OrderSubmitDialog({open, handleClose}) {

    return (
        <Dialog
            open={open}
        >
            <DialogTitle id="alert-dialog-title">
                Auftrag abschließen
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Soll der Auftrag abgeschlossen werden?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => handleClose(false)}>Nein</Button>
                <Button onClick={() => handleClose(true)} color="success">
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default OrderSubmitDialog;