import Chip from "@mui/material/Chip"

export default ({order}) => {
    var color = "success";
    var label = "Offen";
    if (order.status == "completed") {
        color = "warning";
        label = "Erfasst";
    } else if (order.status == "closed") {
        color = "error";
        label = "Übertragen";
    } else if(order.status == "pendingdelete"){
        color = "info";
        label = "Ausstehend: Löschen";
    } else if(order.status == "pendingupdate"){
        color = "info";
        label = "Ausstehend: Speichern";
    } else if(order.status == "pendingcreation"){
        color = "info";
        label = "Ausstehend: Neuanlage";
    }
    return (<Chip label={label} color={color} size="small" />)
}