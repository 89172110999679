import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';
import { readOrders } from '../orders/ordersSlice';

const initialState = {
  isOnline: navigator.onLine,
  pendingRequests: []
}

export const processPendingRequests = createAsyncThunk(
  'offline/processPendingRequests',
  async ({ }, { rejectWithValue, getState, dispatch }) => {
    console.log(processPendingRequests);
    const { auth, offline : { pendingRequests } } = getState();

    const client = createClient(auth.token);
    try {
      var promises = pendingRequests.map(async pendingReq => {
        switch (pendingReq.type) {
          case "order/create":
            await client.post(`/orders${pendingReq.payload.id ? `/${pendingReq.payload.id}` : ''}`, pendingReq.payload);
            dispatch(readOrders());
            break;

          case "order/delete":
            await client.delete(`/orders/${pendingReq.payload}`)
            dispatch(readOrders());
            break;
        }
      });

      await Promise.all(promises);
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  }
)

export const offlineSlice = createSlice({
  name: 'offline',
  initialState,
  reducers: {
    onlineStatusChange(state, action){
      state.isOnline = action.payload
    },
    addPendingRequest(state, action) {
      const orderId = action.payload.payload.id;
      state.pendingRequests = state.pendingRequests.filter(item => item.payload.id != orderId);
      state.pendingRequests.push(action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(processPendingRequests.fulfilled, (state, action) => {
        state.pendingRequests = []
      })
  }
})

// Action creators are generated for each case reducer function
export const selectIsOnline = (state) => state.offline.isOnline;
export const selectCountPendingReq = (state) => state.offline.pendingRequests.length;


export const { onlineStatusChange, addPendingRequest } = offlineSlice.actions

export default offlineSlice.reducer

