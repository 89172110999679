import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './app/store'
import { Provider } from 'react-redux'

import App from './App'
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Orders from './pages/Orders';
import ServiceRecipient from './pages/Create/Steps/ServiceRecipient';
import Accessories from './pages/Create/Steps/Accessories';
import WorkPerformed from './pages/Create/Steps/WorkPerformed';
import GuardedRoute from './components/GuardedRoute';
import { createTheme, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Confirmation from './pages/Create/Steps/Confirmation';

const router = createHashRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/orders",
    element: <GuardedRoute component={Orders} />  
  },
  {
    path: "/",
    element: <GuardedRoute component={Dashboard} />  
  },
  {
    path: "/order/step/serviceRecipient/:orderId?",
    element: <GuardedRoute component={ServiceRecipient} />  
  },
  {
    path: "/order/step/accessories/:orderId?",
    element: <GuardedRoute component={Accessories} />
  },
  {
    path: "/order/step/workPerformed/:orderId?",
    element: <GuardedRoute component={WorkPerformed} />
  },
  {
    path: "/order/step/confirmation/:orderId?",
    element: <GuardedRoute component={Confirmation} />
  }
]);

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(27,36,43)",
    },
    secondary: { 
      main: '#ef6b3d',
    }, 
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <App>
             <RouterProvider router={router} />
            </App>
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
