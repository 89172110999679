import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';

const initialState = {
  workTypes : []
}

export const loadWorkTypes = createAsyncThunk(
  'workType/load',
  // The payload creator receives the partial `{title, content, user}` object
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState();
    const client  = createClient(auth.token);
    // We send the initial data to the fake API server
    try {
      const response = await client.get('/workTypes')
      // The response includes the complete post object, including unique ID
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const workTypeSlice = createSlice({
  name: 'workType',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(loadWorkTypes.fulfilled, (state, action) => {
        state.workTypes = action.payload
      })
  }
})

// Action creators are generated for each case reducer function

export const selectWorkTypes = (state) => state.workType.workTypes;
export default workTypeSlice.reducer