import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CommentIcon from '@mui/icons-material/Comment';
import { Grid } from '@mui/material';
import SignDialog from '../../../components/SignDialog';
import OrderSubmitDialog from '../../../components/OrderSubmitDialog';


import { StepWrapper } from '../StepWrapper';
import fieldLabels from './fieldLabels'
import { selectCurrentOrder, setOrder, changeOrder, resetOrder, createOrder } from '../../../features/currentOrder/currentOrderSlice';
import { setCurrentOrderByOrder, selectOrderByOrderId } from '../../../features/orders/ordersSlice';
import { selectWorkTypes, loadWorkTypes } from '../../../features/workType/workTypeSlice'
import { selectAccessories, loadAccessories } from '../../../features/accessoire/accessoireSlice'
import { loadClients, selectClients } from '../../../features/client/clientSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


const Confirmation = (props) => {
    const { orderId } = useParams();

    const currentOrder = useSelector(state => selectOrderByOrderId(state, orderId));
    const typesOfWorks = useSelector(selectWorkTypes);
    const accessories = useSelector(selectAccessories);
    const clients = useSelector(selectClients);
    const sigCanvas = useRef(null);
    const [signUrl, setSignURL] = useState();
    const [ showSubmitDialog, setShowSubmitDialog ] = useState(false);
   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const order = useSelector(selectCurrentOrder);

    useEffect(() => {
        if (orderId && !order.id || (orderId && orderId != order.id)) {
            dispatch(setOrder(currentOrder));
        }
    }, [orderId]);


    const createAccessoriesList = (items) => {
        return (

            <Grid container>
                {Object.keys(items).map((id) => {
                    const accessorie = accessories.find(item => item.id == id);
                    return (<><Grid item xs={6}>{accessorie.name}</Grid>
                        <Grid item xs={6}>{`${items[id]} Tage`}</Grid>
                    </>);
                })}
            </Grid>);
    }

    const createWorkList = (reports) => {
        return reports.map((report, index) => {
            const typeOfWork = typesOfWorks.find(item => item.id == report.selected_work);
            return <Box key={index} sx={{ mb: 3, borderRadius: 2 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ py: 1, mb: 1 }}>
                        <strong>{typeOfWork.name}</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {typeOfWork.value_lables.map((item, index) => {
                                return (<Grid item container xs={12}>
                                    <Grid item xs={6}>
                                        <strong>{item}</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>{report[`value${index + 1}`]}{item == "Menge" || item.includes("Anzahl") ? "" : "cm"}</div>
                                    </Grid>
                                </Grid>);
                            })}

                            {Object.keys(fieldLabels).filter(label => !!report[label]).map(label => {
                                const fieldLabel = fieldLabels[label];
                                return (<Grid item container xs={12}>
                                    <Grid item xs={6}>
                                        <strong>{fieldLabel.title}</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>{(fieldLabel.values) ? fieldLabel.values[report[label]] : (label == "work") ? report[label].join(", ") : report[label]}</div>
                                    </Grid>
                                </Grid>)
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        })
    }

    const handleSave = () => { 
        setShowSubmitDialog(true);
    }

    const handleStatusChange = (closeOrder) => {
        setShowSubmitDialog(false);
        const newOrder = {
            status : closeOrder ? 'completed' : 'open', 
        };

        if(signUrl){
            newOrder["sign_url"] = signUrl;
        }

        if(order.pivot?.duration){
            newOrder["duration"] = order.pivot?.duration;
        }

        dispatch(changeOrder(newOrder))
        //console.log("SAVE NEW ORDER ", newOrder);
        dispatch(createOrder({})).then(() => navigate("/orders"));
    }

    const handleSignChange = (url) => {
        setSignURL(url);
    }

    const client = clients.find(client => client.id == order.client_id);

    return (
        <StepWrapper activeStep={3} handleSave={handleSave}>
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>Auftragsgeber</Typography>
            <Grid container>
                <Grid item xs={6}><strong>Name</strong></Grid>
                <Grid item xs={6}>{client?.company}</Grid>
                <Grid item xs={6}><strong>Baustelle</strong></Grid>
                <Grid item xs={6}>{order?.name}</Grid>
                <Grid item xs={6}><strong>Adresse</strong></Grid>
                <Grid item xs={6}>{order?.address} <br />{order?.postcode} {order?.city}</Grid>

            </Grid>

            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>Zusatzgeräte</Typography>
            {createAccessoriesList(order.accessories)}
            <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>Ausgeführte Arbeit</Typography>
            {createWorkList(order.reports)}
            <SignDialog currentSignUrl={order?.sign_url} handleSignChange={handleSignChange} />

            <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6} alignItems="end">

                </Grid>
            </Grid>
            <OrderSubmitDialog  open={showSubmitDialog} handleClose={handleStatusChange} />
        </StepWrapper>
    )
};

export default Confirmation