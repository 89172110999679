import { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import WifiIcon from '@mui/icons-material/Wifi';
import LogoutIcon from '@mui/icons-material/Logout';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { onlineStatusChange, selectIsOnline, selectCountPendingReq, processPendingRequests } from '../features/offline/offlineSlice';
import { ListItemButton, ListItemIcon, ListItemText, ListItem } from '@mui/material';


const menuItems = [
    {
        name: "Dashboard",
        icon: <HomeIcon />,
        href: "/"
    },
    {
        name: "Leistungsnachweis anlegen",
        icon: <AddIcon />,
        href: "/order/step/serviceRecipient"
    },
    {
        name: "Meine Leistungsnachweis",
        icon: <ListIcon />,
        href: "/orders"
    },
];

export default ({ children }) => {
    const prendingRequests = useSelector(selectCountPendingReq);

    const [showDrawer, setShowDrawer] = useState(false);
    const isOnline = useSelector(selectIsOnline);
    const getList = () => (
        <div onClick={() => setShowDrawer(false)}>
            {menuItems.map((item, index) => (
                <ListItem disablePadding>
                    <ListItemButton to={item.href} component={Link} key={index}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            ))}
        </div>
    );

    var onlineIcon = <WifiIcon />;
    if (!isOnline) {
        onlineIcon = <Badge badgeContent={prendingRequests} color="secondary"><WifiOffIcon /></Badge>;
    }

    return (<Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => setShowDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
                <div style={{ flexGrow: 1, textAlign: 'center' }}>
                    <Link to="/"><img src="/logo.webp" width={80} /></Link>
                </div>
                <div>
                    {onlineIcon}
                </div>
            </Toolbar>
        </AppBar>
        {children}
        <Drawer
            variant="temporary"
            open={showDrawer}
            anchor={"left"}
            onClose={() => setShowDrawer(false)}
        >
            <IconButton onClick={() => setShowDrawer(false)} sx={{ marginLeft: 'auto', marginRight: 0, paddingTop: 2, paddingRight: 2 }}>
                <CloseIcon />
            </IconButton>
            {getList()}
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </ListItem>
        </Drawer>
    </Box>)
}