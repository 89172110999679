import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';

const initialState = {
    accessories : []
}

export const loadAccessories = createAsyncThunk(
  'accessoire/load',
  // The payload creator receives the partial `{title, content, user}` object
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState();
    console.log(auth.token);
    const client  = createClient(auth.token);
    // We send the initial data to the fake API server
    try {
      const response = await client.get('/accessories')
      // The response includes the complete post object, including unique ID
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const accessoireSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(loadAccessories.fulfilled, (state, action) => {
        state.accessories = action.payload
      })
  }
})

// Action creators are generated for each case reducer function

export const selectAccessories = (state) => state.accessoire?.accessories;
export default accessoireSlice.reducer