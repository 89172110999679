import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { login, selectError } from '../features/auth/authSlice';
import { loadAccessories } from '../features/accessoire/accessoireSlice';
import { loadClients } from '../features/client/clientSlice';
import { loadWorkTypes } from '../features/workType/workTypeSlice';
import { loadExtraWork } from '../features/extraWork/extraWorkSlice';


export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    });

    const error = useSelector(selectError);

    const handleSubmit = (e) => {
        e.preventDefault(); dispatch(login(credentials)).then(() => {
            navigate("/");
            dispatch(loadClients());
            dispatch(loadAccessories());
            dispatch(loadWorkTypes());
            dispatch(loadExtraWork())
        });
    }
    const onHandleChange = (e) => setCredentials({ ...credentials, [e.target.name]: e.target.value });

    return (<Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {error.message && <Alert severity="error" sx={{ width: 1, mb: 1 }}>{error.message}</Alert>}
            <img src="logo.webp" width={200} />
            <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
                Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email-Addresse"
                    name="email"
                    error={!!error?.errors?.email}
                    value={credentials.email}
                    helperText={error?.errors?.email}
                    onChange={onHandleChange}
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Passwort"
                    type="password"
                    id="password"
                    error={!!error?.errors?.password}
                    helperText={error?.errors?.password}
                    value={credentials.password}
                    onChange={onHandleChange}
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Anmelden
                </Button>
            </Box>
        </Box>
    </Container>)
}