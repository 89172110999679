import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../features/auth/authSlice';

const GuardedRoute = ({ component: Component, auth, ...rest }) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    if (!isLoggedIn) return <Navigate to="/login" />;
    return (<Component />)
}

export default GuardedRoute;