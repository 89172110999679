import React, { useState, Fragment, useEffect } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import AddIcon from '@mui/icons-material/Add';
import DraftsIcon from '@mui/icons-material/Drafts';
import Button from '@mui/material/Button';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { selectWorkTypes,loadWorkTypes } from '../../../features/workType/workTypeSlice'
import { selectCurrentOrder, selectReportsByWorkId, deleteReport, createOrder, setOrder } from '../../../features/currentOrder/currentOrderSlice'
import { selectOrderByOrderId } from '../../../features/orders/ordersSlice';
import { CreateEntryModal } from '../CreateEntryModal';
import { StepWrapper } from '../StepWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { loadExtraWork } from '../../../features/extraWork/extraWorkSlice';
import fieldLabels from './fieldLabels'

function WorkPerformedRow({ workType, handleToggleCheckbox, handleToggle, checked, open, openEditDialog, showCreateModal }){
    const dispatch = useDispatch();

    const reports = useSelector(state => selectReportsByWorkId(state, workType.id))
    const deleteItem = report => dispatch(deleteReport(report));

    return (<Fragment key={workType.id}>
        <ListItemButton key={workType.id} onClick={handleToggle(workType.id)} >
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={!!reports?.length}
                    onClick={(e) => handleToggleCheckbox(e, workType.id)}
                />
            </ListItemIcon>
            <ListItemText primary={workType.name} />
            {checked.indexOf(workType.id) !== -1 ? ((open === workType.id) ? <ExpandLess /> : <ExpandMore />) : <ExpandMore />}
        </ListItemButton>
        <Collapse in={workType.id === open} timeout="auto" unmountOnExit>
            {reports && reports.map((report, index) =>
                <Box key={index} sx={{ p: 2, mb: 3, border: '1px dashed grey', borderRadius: 2 }}>
                    <Grid container spacing={2}>
                    {workType.value_lables.map((item, index) => {
                                            return (<Grid item container xs={12}>
                                                <Grid item xs={6}>
                                                    <strong>{item}</strong>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>{report[`value${index + 1}`]}{item == "Menge" || item.includes("Anzahl") ? "" : "cm"}</div>
                                                </Grid>
                                            </Grid>);
                                        })}

                                        {Object.keys(fieldLabels).filter(label => !!report[label]).map(label => {
                                            console.log('labels: ' + label);
                                            const fieldLabel = fieldLabels[label];
                                            return (<Grid item container xs={12}>
                                                <Grid item xs={6}>
                                                    <strong>{fieldLabel.title}</strong>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>{(fieldLabel.values) ? fieldLabel.values[report[label]] : (label == "work") ? report[label].join(", ") : (label == "worn_material")? report[label] + "cm" : report[label]}</div>
                                                </Grid>
                                            </Grid>)
                                        })}
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="center" spacing={2}>
                                <Button startIcon={<EditIcon />} variant="contained" size="small" onClick={openEditDialog(report, workType)}>
                                    Bearbeiten
                                </Button>
                                <Button startIcon={<DeleteIcon />} color="error" size="small" variant="contained" onClick={() => deleteItem(report)}>
                                    Löschen
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Stack direction="row" sx={{ mt: 3 }} justifyContent="center" spacing={2}>
                <Button startIcon={<AddIcon />} onClick={() => showCreateModal(workType)} variant="contained">
                    Neuer Eintrag
                </Button>
            </Stack>

        </Collapse>
    </Fragment>);
}

function WorkPerformed() {
    const{ orderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const typesOfWorks = useSelector(selectWorkTypes);
    const order = useSelector(selectCurrentOrder);
    const currentOrder = useSelector(state => selectOrderByOrderId(state, orderId));
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedWork, setSelectedWork] = useState(null);
    const [changeReport, setChangeReport] = useState(null);

    useEffect(() => {
        if(orderId && !order.id){
            console.log("currentOrder: ",currentOrder)
            dispatch(setOrder(currentOrder));
        }
        dispatch(loadWorkTypes());
        dispatch(loadExtraWork());
    }, [orderId])

    const [checked, setChecked] = React.useState([0]);

    const handleToggleCheckbox = (e, value) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("Checkbox clicked");
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
            if (open === value) {
                setOpen(false)
            }
        }

        setChecked(newChecked);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            setOpen(value)
        } else {
            if (open === value) {
                setOpen(false)
            } else {
                setOpen(value)

            }
        }

        setChecked(newChecked);
    };

    const showCreateModal = (selectedWork) => {
        setChangeReport(null);
        setSelectedWork(selectedWork)
        setOpenDialog(true);
    }

    const openEditDialog = (report, selectedWork) => (e) => {
        e.preventDefault();
        setChangeReport(report)
        setSelectedWork(selectedWork)
        setOpenDialog(true);
    }

    const handleSave = () => {
        //dispatch(createOrder(order)).then(() => );
        navigate(`/order/step/confirmation/${order?.id || ''}`)
    }

    console.log("order: ", order)

    return (
        <StepWrapper activeStep={2} handleSave={handleSave}>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >

                {typesOfWorks.map((value, index) => <WorkPerformedRow workType={value} key={index} checked={checked} openEditDialog={openEditDialog} showCreateModal={showCreateModal} open={open} handleToggleCheckbox={handleToggleCheckbox} handleToggle={handleToggle} />)}
            </List>
            <CreateEntryModal open={openDialog} changeReport={changeReport} selectedWork={selectedWork}  handleClose={() => setOpenDialog(false)} />
            </StepWrapper>
    );
}

export default WorkPerformed 