import { Select } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const FormSelect = ({ name, children, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller control={control} name={name} render={({ field })=> (
        <Select
          {...otherProps}
          {...field}
          error={!!errors[name]}
        >
            {children}
        </Select>
      )}
    />
  );
};

export default FormSelect;
