import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';
import { addPendingRequest } from '../offline/offlineSlice';
import { readOrders, setPendingStatus, addOrder } from '../orders/ordersSlice';

const initialState = {
  order: {
    reports: [],
    accessories: [],
    name: ""
  }
}

export const createOrder = createAsyncThunk(
  'orders/create',
  async (_, { rejectWithValue, getState, dispatch }) => {
    console.log(getState());
    const { auth, currentOrder : { order }, offline : { isOnline } } = getState();

    console.log(isOnline);
    if(!isOnline){
      dispatch(addPendingRequest({ type : "order/create", payload : order}));
      dispatch(setPendingStatus({ orderId: order.id, status: (order.id) ? "pendingupdate" : "pendingcreation" }));
      if(!order.id){
        dispatch(addOrder({...order, status: "pendingcreation"}));
      }
      return rejectWithValue({});
    }
    const client = createClient(auth.token);
 
    // We send the initial data to the fake API server
    try {
      const res = await client.post(`/orders${order.id ? `/${order.id}` : ''}`, order);
      //dispatch(addOrder(order))
      dispatch(readOrders());
      return res.data.id;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const currentOrderSlice = createSlice({
  name: 'currentOrder',
  initialState,
  reducers: {
    setOrder(state, action) {
      state.order = action.payload;
      if (action.payload.accessories && Array.isArray(action.payload.accessories)) {
        state.order = {...state.order, accessories : action.payload.accessories.reduce((prev, value) => { prev[value.id] = value.pivot.amount; return prev; }, {}) };
      }
    },
    changeOrder(state, action) {
      state.order = { ...state.order, ...action.payload };
    },
    addReport(state, action) {
      state.order.reports.push(action.payload);
    },
    replaceReport(state, action) {
      const { values, changeReport } = action.payload;
      const changeIndex = state.order.reports.findIndex(function (cur) {
        return Object.keys(changeReport).every(function (key) {
          return Array.isArray(changeReport[key]) ? JSON.stringify(changeReport[key]) === JSON.stringify(cur[key]) : changeReport[key] === cur[key];
        });
      });
      if (changeIndex >= 0) {
        state.order.reports[changeIndex] = values;
      }
    },
    deleteReport(state, action) {
      const report = action.payload;
      const changeIndex = state.order.reports.findIndex(function (cur) {
        return Object.keys(report).every(function (key) {
          return Array.isArray(report[key]) ? JSON.stringify(report[key]) === JSON.stringify(cur[key]) : report[key] === cur[key];
        });
      });

      if (changeIndex >= 0) {
        state.order.reports = state.order.reports.filter((item, index) => index !== changeIndex);
      }
    },
    resetOrder(state) {
      state.order = {...initialState.order};
    }
  }
})

// Action creators are generated for each case reducer function
export const selectReportsByWorkId = (state, workId) => {
  const { reports } = selectCurrentOrder(state);
  return reports?.filter(report => report.selected_work === workId)
}

export const selectCurrentOrder = (state) => state.currentOrder.order;

export const { setFilters, setPage, setOrder, addReport, replaceReport, deleteReport, setCurrentOrderByOrder, resetOrder, changeOrder } = currentOrderSlice.actions

export default currentOrderSlice.reducer