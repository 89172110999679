import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createClient } from '../../app/client';

const initialState = {
    clients : []
}

export const loadClients = createAsyncThunk(
  'client/load',
  // The payload creator receives the partial `{title, content, user}` object
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState();
    const client  = createClient(auth.token);
    // We send the initial data to the fake API server
    try {
      const response = await client.get('/clients')
      // The response includes the complete post object, including unique ID
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
)

export const clienSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(loadClients.fulfilled, (state, action) => {
        state.clients = action.payload
      })
  }
})

// Action creators are generated for each case reducer function

export const selectClients = (state) => state.client?.clients;
export default clienSlice.reducer