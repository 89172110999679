export default {
    fe: { title: "Betonanteil" },
    location: { title: "Standort" },
    material: {
        title: "Material", values: {
            b: "Beton",
            m: "Mauerwerk"
        }
    },
    other: { title: "Sonstiges" },
    wd: {
        title: "Wand/Decke", values: {
            w: "Wand",
            d: "Decke"
        }
    },
    work: {
        title: "Ausgeführt Arbeiten"
    },
    work_height: {
        title: "Arbeitshöhe", values: {
            1: "unter 2m",
            2: "über 2m",
            3: "über 4m"
        }
    },
    worn_material: { title: "Materialabtrag" }
}