import React, { useState, useEffect, useImperativeHandle } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { useNavigate, useParams } from 'react-router-dom';
import { selectAccessories, loadAccessories } from '../../../features/accessoire/accessoireSlice'
import { StepWrapper } from '../StepWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { selectCreateOrder, selectOrderByOrderId } from '../../../features/orders/ordersSlice';
import { selectCurrentOrder, setOrder, changeOrder } from '../../../features/currentOrder/currentOrderSlice';

const Accessories = () => {
    const { orderId } = useParams();
    const  currentOrder = useSelector(state => selectOrderByOrderId(state, orderId));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessories = useSelector(selectAccessories)
    const order = useSelector(selectCurrentOrder);


    const [data, setData] = useState({});

    useEffect(() => {
        console.log(order);
        console.log(currentOrder);
        if (orderId && Array.isArray(order.accessories)) {
            setData(order.accessories.reduce((prev, value) => { prev[value.id] = value.pivot.amount; return prev; }, {}));
        } else if(order && order.accessories){
            setData(order.accessories)
        }
    }, [order]);


    useEffect(() => {
        console.log(orderId);
        if(orderId && !order.id){
            dispatch(setOrder(currentOrder));
        }
        dispatch(loadAccessories())

        //if (order.id && order.accessories.length) {
        //    setData(order.accessories.reduce((prev, val) => { prev[val.id] = val.pivot.amount; return prev }, {}));
        //}
    }, [orderId]);


    const handleToggle = (value) => () => {
        if (data[value] > 0) {
            const copyData = {...data};
            delete copyData[value];
            setData(copyData);
        } else {
            setData({ ...data, ...{ [value]: 1 } });
        }
    };

    const handleChange = id => e => {
        var value = e.target.value;
        setData({ ...data, ...{ [id.toString()]: value } });
    };


    const handleSave = async () => {
        const orderAccessories = Object.keys(data).reduce((prev, item) => {
            const value = data[item];
            if (value > 0) {
                prev[item] = value;
            }
            return prev;
        }, {});
        dispatch(changeOrder({ accessories: orderAccessories }))
        if(order && order.id){
            navigate(`/order/step/workPerformed/${order.id}`);
        } else {
            navigate(`/order/step/workPerformed/`);
        }
    };

    return (
        <StepWrapper activeStep={1} handleSave={handleSave}>
            <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>Zusatzgeräte</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {accessories.map((accessorie) => {
                    const labelId = `checkbox-list-label-${accessorie.id}`;
                    const checked = data.hasOwnProperty(accessorie.id);
                    return (
                        <>
                            <ListItem
                                key={accessorie.id}
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(accessorie.id.toString())} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${accessorie.name} (+${accessorie.price}€)`} />
                                </ListItemButton>
                            </ListItem>
                            <Collapse in={checked} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                                <TextField
                                    id="outlined-required"
                                    label="Anzahl"
                                    size="small"
                                    fullWidth
                                    value={data[accessorie.id.toString()]}
                                    onChange={handleChange(accessorie.id)}
                                    disabled={Object.keys(data).indexOf(accessorie.id.toString()) == -1}
                                    inputProps={{ inputMode: 'numeric', pattern: 'number' }}
                                />
                            </Collapse>
                        </>

                    );
                })}
            </List>
        </StepWrapper>
    )
};

export default Accessories