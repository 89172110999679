import { useState, forwardRef, useRef, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SignatureCanvas from 'react-signature-canvas'
import DeleteIcon from '@mui/icons-material/Delete';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignDialog({ handleSignChange, currentSignUrl }) {
    const [imageUrl, setImageUrl] = useState();
    const [open, setOpen] = useState(false);
    const sigCanvas = useRef(null);
    var sigPad = {}

    useEffect(() => {
        if(currentSignUrl){
            setImageUrl(`http://fierce-wildwood-72719.herokuapp.com/storage/signs/${currentSignUrl}`)
        }
    }, [currentSignUrl]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const rotateBase64Image90deg = (base64Image, isClockwise) => {
        return new Promise((resolve) => {
            var offScreenCanvas = document.createElement('canvas');
            var offScreenCanvasCtx = offScreenCanvas.getContext('2d');

            var img = new Image();
            img.src = base64Image;
            img.onload = function () {
                // set its dimension to rotated size
                offScreenCanvas.height = img.width;
                offScreenCanvas.width = img.height;

                // rotate and draw source image into the off-screen canvas:
                if (isClockwise) {
                    offScreenCanvasCtx.rotate(90 * Math.PI / 180);
                    offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
                } else {
                    offScreenCanvasCtx.rotate(-90 * Math.PI / 180);
                    offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
                }
                offScreenCanvasCtx.drawImage(img, 0, 0);
                resolve(offScreenCanvas.toDataURL());
            }
        });

    }

    const handleSaveSign = async () => {
        const currentImageUrl = await rotateBase64Image90deg(sigPad.toDataURL(), true)
        setImageUrl(currentImageUrl);
        handleSignChange(currentImageUrl);
        handleClose();
    };

    const handleDelete = () => {
        setImageUrl(null);
    }

    return (
        <>
            {imageUrl != null &&
                <Box sx={{ position: "relative" }}>
                    <img src={imageUrl} style={{ width: "100%" }} />
                    <IconButton sx={{ position: "absolute", top : 0, right: 0 }} onClick={handleDelete}><DeleteIcon /></IconButton>
                </Box>}
            <Button variant="outlined" onClick={handleClickOpen}>
                Unterschrift einfügen
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            variant="contained"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Unterschrift
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSaveSign}>
                            Speichern
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{ flex: '1 1 100%' }}>
                    <SignatureCanvas penColor='black' ref={(ref) => { sigPad = ref }} canvasProps={{ className: 'signature' }} />
                </Box>

            </Dialog>
        </>)
}
