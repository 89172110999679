import axios from 'axios';

export const createClient = (token = null) => {
  var options = {
    baseURL: 'https://fierce-wildwood-72719.herokuapp.com/api',
    //baseURL: 'http://localhost/api',
  };
  if (token) {
    options["headers"] =
    {
      'Authorization': `Bearer ${token}`
    };
  }
  return axios.create(options);
}